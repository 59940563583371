import type { Theme } from '@material-ui/core/styles';
// In @material-ui/core v5, we should be able to import StyleRules from @material-ui/core/styles as well.
// In v4, that version has the wrong type parameter order.
import type { StyleRules } from '@material-ui/styles';

interface HoverProps {
  hover: boolean;
  showDragOnHover: boolean;
}

const formNestedFieldStyles = (theme: Theme) => ({
  crossSellingRemoveButton: {
    backgroundColor: '#2E3042 !important',
    border: 'none !important',
    color: 'white',
    fontSize: '20px',
    minWidth: 0,
    padding: 0,
    position: 'absolute',
    right: '-8px',
    top: '-9px',
    zIndex: 9,
  },
  dragHandle: {
    '& button': {
      background: 'none !important',
      border: 'none !important',
    },
    cursor: 'move',
    fontSize: '20px',
    marginLeft: theme.spacing(-1),
    overflow: 'visible',
    visibility: ({ hover, showDragOnHover }) => {
      if (showDragOnHover) {
        if (hover) {
          return 'visible';
        } else {
          return 'hidden';
        }
      } else {
        return 'visible';
      }
    },
    width: '20px',
    zIndex: 9,
  },
  fieldWithDragHandle: {
    background: ({ hover, showDragOnHover }) => {
      if (showDragOnHover) {
        if (hover) {
          return theme.palette.white.main;
        } else {
          return 'none';
        }
      } else {
        return 'none';
      }
    },
    marginLeft: theme.spacing(1),
    width: '100%',
  },
  removeButton: {
    background: 'none !important',
    border: 'none !important',
    fontSize: '20px',
    minWidth: 0,
    padding: 0,
    position: 'absolute',
    right: '-8px',
    top: '-9px',
    zIndex: 9,
  },
  webBuilderRemoveButton: {
    background: '#E0E0E0',
    border: 'none !important',
    color: '#2E3042',
    fontSize: '20px',
    minWidth: 0,
    padding: 0,
    position: 'absolute',
    right: '-8px',
    top: '-9px',
    zIndex: 9,
  },
  wrapper: {
    marginBottom: theme.spacing(2),
    position: 'relative',
    width: '100%',
  },
  wrapperInner: {},
  wrapperInnerWithDragHandle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },
} as const satisfies StyleRules<HoverProps>);

export default formNestedFieldStyles;
