import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Divider, Grid, Typography } from '@popmenu/common-ui';
import { withIntl } from '../../../utils/withIntl';
import restaurantLocationsQuery from '../../../libs/gql/queries/locations/restaurantWithLocationNamesQuery.gql';
import Query from '../../../shared/Query';
import { sortByKey } from '../../../utils/arrays';
import { multiValidator } from '../../../utils/forms';
import { formatPhone } from '../../../utils/strings';
import { classNames } from '../../../utils/withStyles';

import {
  CheckBoxGroup,
  RadioGroup,
  TextFieldGroup,
  TextAreaGroup,
  FileUploadGroup,
  DateTimePickerGroup,
  NestedField,
  SelectGroup,
} from '../../../admin/shared/forms/BasicForm';
import { AH } from '../../shared/AccessibleHeading';

// Real notBlankValidator method will need to be re-imported once its updated: SC79233
const notBlankValidator = fieldName => (value, instance) => {
  instance.form.setFieldMeta(fieldName, { error: value ? null : 'Cannot be blank' });
};

const AdvancedFormsFieldFactory = ({ t, setValue, values, advancedForm, classes, restaurantId, setLocationId }) => {
  if (!advancedForm) return null;

  const calculateColumnSize = (type, itemsLength) => {
    if (type === 'md' && itemsLength >= 3) return 4;
    if (type === 'lg' && itemsLength >= 4) return 3;
    return 12 / itemsLength;
  };

  const handleCheckboxChange = (option, checked, index) => {
    let options = [];
    const indexedQuestion = `field_${index}`;
    if (!values[indexedQuestion]) {
      setValue(indexedQuestion, option);
      return;
    }
    if (checked) {
      options = [...values[indexedQuestion].split(', '), option];
    } else {
      options = values[indexedQuestion].split(', ').filter(val => val !== option);
    }
    setValue(indexedQuestion, options.join(', '));
  };

  const getLabel = advancedFormField => `${advancedFormField.placeholder || ' '} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''}`;

  const getFileUploadLabel = advancedFormField => `${advancedFormField.placeholder || t('advanced_forms.file_attachment')} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''} (pdf, doc, docx):`;

  const getLocationPickerLabel = advancedFormField => `${advancedFormField.placeholder || t('advanced_forms.select_location')}`;

  return (
    <React.Fragment>
      {advancedForm && advancedForm.fields.map((advancedFormField) => {
        const fieldName = `field_${advancedFormField.id}`;
        switch (advancedFormField.fieldType) {
          case 'short_question':
          case 'first_name':
          case 'last_name':
            return (
              <div className={classes.customTextField}>
                <TextFieldGroup
                  field={fieldName}
                  title={`${advancedFormField.placeholder || ' '} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''}`}
                  validate={advancedFormField.isRequired ? notBlankValidator(fieldName) : null}
                  helperText={advancedFormField.helperText}
                  className={'pm-acf-short-question'}
                />
              </div>
            );
          case 'long_question':
            return (
              <div className={classes.customTextField}>
                <TextAreaGroup
                  field={fieldName}
                  title={`${advancedFormField.placeholder || ' '} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''}`}
                  validate={advancedFormField.isRequired ? notBlankValidator(fieldName) : null}
                  helperText={advancedFormField.helperText}
                  className={'pm-acf-long-question'}
                />
              </div>
            );
          case 'date':
            return (
              <React.Fragment>
                <Typography variant="body1" className={classes.fieldLabel}>
                  {advancedFormField.placeholder}
                </Typography>
                <DateTimePickerGroup
                  className={classNames(classes.customDateTimePicker, 'pm-acf-date-time-picker')}
                  field={fieldName}
                  placeholder={`${t('advanced_forms.datePlaceholder')} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''}`}
                  validate={advancedFormField.isRequired ? notBlankValidator(fieldName) : null}
                  helperText={advancedFormField.helperText}
                />
              </React.Fragment>
            );
          case 'file_upload':
            return (
              <React.Fragment>
                <Typography variant="body1" className={classes.fieldLabel}>
                  {getFileUploadLabel(advancedFormField)}
                </Typography>
                <FileUploadGroup
                  aria-label={t('custom_form_entries.attachment')}
                  accept=".pdf,.doc,.docx,image/*"
                  className={classNames(classes.customFileUpload, 'pm-acf-file-upload')}
                  onChange={(e) => {
                    const file = e && e.target && e.target.files[0];
                    if (!file) {
                      setValue(fieldName, null);
                    }
                  }}
                  field={fieldName}
                />
              </React.Fragment>
            );
          // Currently no way for user to require this field when creating forms: SC79894
          case 'check_box':
            return (
              <NestedField field={`_${fieldName}`} className={classNames(classes.fieldLabel, 'pm-acf-checkbox')}>
                {getLabel(advancedFormField)}
                <Grid container inline>
                  {advancedFormField.options.map((option, i) => (
                    <Grid
                      item
                      xs={12}
                      md={calculateColumnSize('md', advancedFormField.options.length)}
                      lg={calculateColumnSize('lg', advancedFormField.options.length)}
                      className={classes.fieldLabel}
                    >
                      <CheckBoxGroup
                        formGroupProps={{
                          className: classes.customCheckboxOption,
                        }}
                        field={`${i}_option`}
                        onChange={checked => handleCheckboxChange(option, checked, advancedFormField.id)}
                        title={`${option || ' '}`}
                        className={classes.checkBoxPicker}
                      />
                    </Grid>
                  ))}
                </Grid>
              </NestedField>
            );
          // Currently no way for user to require this field when creating forms: SC79895
          case 'multiple_choice':
            return (
              <div className={classNames(classes.fieldLabel, 'pm-acf-multiple-choice')}>
                {getLabel(advancedFormField)}
                <RadioGroup
                  field={fieldName}
                  options={advancedFormField.options.map(value => ({
                    label: value,
                    value,
                  }))}
                  helperText={advancedFormField.helperText}
                  formControlProps={{
                    className: classes.multipleChoice,
                  }}
                />
              </div>
            );
          case 'phone_number':
            return (
              <div className={classes.customTextField}>
                <TextFieldGroup
                  field={fieldName}
                  title={`${advancedFormField.placeholder || ' '} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''}`}
                  type="tel"
                  validate={advancedFormField.isRequired ? multiValidator(['phone', 'notBlank']) : multiValidator(['phone'])}
                  helperText={advancedFormField.helperText || t('models.user.phone_helper_text')}
                  onTextChange={(val) => {
                    if (val) {
                      setValue(fieldName, formatPhone(val));
                    }
                  }}
                  className={'pm-acf-phone-number'}
                />
              </div>
            );
          case 'email_address':
            return (
              <div className={classes.customTextField}>
                <TextFieldGroup
                  type="email"
                  field={fieldName}
                  title={`${advancedFormField.placeholder || 'Email Address'} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''}`}
                  validate={advancedFormField.isRequired ? multiValidator(['email', 'notBlank']) : multiValidator(['email'])}
                  helperText={advancedFormField.helperText}
                  className={'pm-acf-email'}
                />
              </div>
            );
          // Currently no way for user to require address when creating forms: SC79896
          case 'address':
            return (
              <React.Fragment>
                <NestedField field={fieldName}>
                  <div className={classes.customTextField}>
                    <TextFieldGroup
                      field="addressLine1"
                      title={`${(advancedFormField.addressPlaceholders && advancedFormField.addressPlaceholders[0]) || 'Street Address'} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''}`}
                      className={'pm-acf-address-line1'}
                    />
                  </div>
                  <div className={classes.customTextField}>
                    <TextFieldGroup
                      field="addressLine2"
                      title={`${(advancedFormField.addressPlaceholders && advancedFormField.addressPlaceholders[1]) || 'Street Address Line 2'}`}
                      className={'pm-acf-address-line2'}
                    />
                  </div>
                  <div className={classes.customTextField}>
                    <TextFieldGroup
                      field="city"
                      title={`${(advancedFormField.addressPlaceholders && advancedFormField.addressPlaceholders[2]) || 'City'} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''}`}
                      className={'pm-acf-address-city'}
                    />
                  </div>
                  <Grid container spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <div className={classes.customTextField}>
                        <TextFieldGroup
                          field="state"
                          title={`${(advancedFormField.addressPlaceholders && advancedFormField.addressPlaceholders[3]) || 'State'} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''}`}
                          className={'pm-acf-address-state'}
                        />
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className={classes.customTextField}>
                        <TextFieldGroup
                          field="zipCode"
                          title={`${(advancedFormField.addressPlaceholders && advancedFormField.addressPlaceholders[4]) || 'Zip Code'} ${advancedFormField.isRequired ? t('advanced_forms.advancedFormRequired') : ''}`}
                          className={'pm-acf-address-zip-code'}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </NestedField>
              </React.Fragment>
            );
          case 'dropdown':
            return (
              <React.Fragment>
                <Typography variant="body1" className={classes.fieldLabel}>
                  {getLabel(advancedFormField)}
                </Typography>
                <SelectGroup
                  field={fieldName}
                  options={advancedFormField.options.map(value => ({
                    label: value,
                    value,
                  }))}
                  placeholder="Select an option"
                  validate={advancedFormField.isRequired ? notBlankValidator(fieldName) : null}
                  helperText={advancedFormField.helperText}
                  className={classes.dropdownPicker}
                />
              </React.Fragment>
            );
          case 'location_picker':
            return (
              <Query
                query={restaurantLocationsQuery}
                variables={{ restaurantId }}
              >
                {({ data, loading }) => {
                  if (loading || !data || !data.restaurant.locations) {
                    return null;
                  }
                  if (data.restaurant.locations.length < 2) {
                    return null;
                  }

                  const { locations } = data.restaurant;
                  const enabledLocations = locations.filter(location => location.isLocationEnabled);
                  const sortedLocations = sortByKey(enabledLocations, 'name');
                  return (
                    <React.Fragment>
                      <Typography variant="body1" className={classes.fieldLabel}>
                        {getLocationPickerLabel(advancedFormField)}
                      </Typography>
                      <SelectGroup
                        field={fieldName}
                        isSearchable={false}
                        options={sortedLocations.map(location => ({
                          label: location.name,
                          value: location.name,
                        }))}
                        onSelected={(locationName) => {
                          const selectedLocation = sortedLocations.find(location => location.name === locationName);
                          setLocationId(selectedLocation.id);
                        }}
                        validate={advancedFormField.isRequired ? notBlankValidator(fieldName) : null}
                        helperText={advancedFormField.helperText}
                        className={classes.locationPicker}
                      />
                    </React.Fragment>
                  );
                }}
              </Query>
            );
          case 'header':
            return (
              <AH typography variant="h4" className={classNames(classes.customDesignElement, 'pm-acf-header')}>
                {advancedFormField.placeholder || 'Header'}
              </AH>
            );
          case 'subheader':
            return (
              <AH typography variant="h6" className={classNames(classes.customDesignElement, 'pm-acf-subheader')}>
                {advancedFormField.placeholder || 'Subheader'}
              </AH>
            );
          case 'divider':
            return <Divider className={classNames(classes.divider, 'pm-acf-divider')} />;
          case 'spacer':
            return <div className={classNames(classes.spacer, 'pm-acf-spacer')} />;
          case 'description':
            return (
              <Typography variant="body1" className={classNames(classes.customDesignElement, 'pm-acf-description')} style={{ whiteSpace: 'pre-line' }}>
                {advancedFormField.placeholder || 'Description'}
              </Typography>
            );
          default:
            return null;
        }
      })}
    </React.Fragment>
  );
};

AdvancedFormsFieldFactory.defaultProps = {};

AdvancedFormsFieldFactory.propTypes = {
  classes: PropTypes.object.isRequired,
  restaurantId: PropTypes.number.isRequired,
  setLocationId: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
)(AdvancedFormsFieldFactory);
