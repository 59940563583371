import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import FormGroup from './FormGroup';

const RadioGroup = ({ color, field, formControlProps, formGroupProps, inline, inputProps, nowrap, onChange, options, row, title, validate, ...props }) => {
  const { value, setValue, meta: { error } } = useField(field, { validate });

  let styles = {};
  if (inline) {
    styles = { flexDirection: 'row', justifyContent: 'center' };
  }

  if (nowrap) {
    styles.flexWrap = 'nowrap';
  }
  // material-ui converts value to a string internally already
  // Doubly enforcing that here so that if they change that later it won't break everything
  return (
    <FormGroup error={error} {...formGroupProps}>
      {title && (
        <FormLabel component="legend">{title}</FormLabel>
      )}
      <MuiRadioGroup
        aria-label={props['aria-label'] || title}
        data-cy="radio_buttons"
        data-tour-id={props['data-tour-id']}
        name={field}
        onChange={(e, nextValue) => {
          const option = options.find(o => String(o.value) === String(nextValue));
          setValue(option && option.value);
          if (typeof onChange === 'function') {
            onChange(nextValue);
          }
        }}
        value={String(value)}
        row={row}
        style={styles}
      >
        {options.map((option, i) => (
          <React.Fragment key={i}>
            <FormControlLabel
              data-tour-id={option.dataTourId}
              key={option.value}
              control={(
                <Radio
                  checked={String(option.value) === String(value)}
                  color={color}
                  inputProps={{
                    'aria-label': option.label || option.value,
                    'data-cy': `${field}_radio_${String(option.value)}`,
                    ...inputProps,
                  }}
                />
              )}
              label={option.label}
              value={String(option.value)}
              disabled={option.disabled}
              {...formControlProps}
            />
            {option.labelContent}
          </React.Fragment>
        ))}
      </MuiRadioGroup>
    </FormGroup>
  );
};

RadioGroup.defaultProps = {
  'aria-label': null,
  color: 'default',
  'data-tour-id': null,
  formControlProps: {},
  formGroupProps: {},
  inline: false,
  inputProps: {},
  nowrap: false,
  onChange: null,
  title: null,
  validate: null,
};

RadioGroup.propTypes = {
  'aria-label': PropTypes.string,
  color: PropTypes.string,
  'data-tour-id': PropTypes.string,
  field: PropTypes.string.isRequired,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  inline: PropTypes.bool,
  inputProps: PropTypes.object,
  nowrap: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    labelContent: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  })).isRequired,
  title: PropTypes.string,
  validate: PropTypes.func,
};

export default RadioGroup;
