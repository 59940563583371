import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { themeShape, withTheme } from '../../../utils/withTheme';
import { withStyles } from '../../../utils/withStyles';
import { withIntl } from '../../../utils/withIntl';
import advancedFormStyles from './styles';
import AdvancedFormsFieldFactory from './AdvancedFormsFieldFactory';

import BasicForm, {
  SubmitGroup,
} from '../../../admin/shared/forms/BasicForm';
import Captcha from '../../../shared/Captcha';
import createAdvancedFormEntry from '../../../libs/gql/mutations/advanced_forms/createAdvancedFormEntryMutation.gql';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';
import { snakeCaseToTitleCase } from '../../../utils/strings';

const AdvancedFormsSection = ({ t, advancedForm, classes, restaurant }) => {
  const [renderCaptcha, setRenderCaptcha] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const statusRef = useRef(null);
  useEffect(() => {
    setRenderCaptcha(true);
  }, [setRenderCaptcha]);

  useEffect(() => {
    if (statusRef.current) {
      statusRef.current.focus();
    }
  });

  if (submitted) {
    return (
      <div ref={statusRef} className="pm-custom-form-thanks" tabIndex="-1">
        <p aria-live="assertive" role="status">
          <strong>
            <FormattedMessage
              id="custom_form_entries.thanks_heading"
              defaultMessage="Thank you for reaching out!"
            />
          </strong>
          <br />
          <FormattedMessage
            id="custom_form_entries.thanks_body"
            defaultMessage="We appreciate you contacting us. We'll get back to you soon."
          />
        </p>
      </div>
    );
  }

  const formatAnswers = (answers) => {
    const designFields = ['description', 'divider', 'header', 'subheader', 'spacer'];
    const formFields = advancedForm.fields.filter(field => !designFields.includes(field.fieldType));

    const formattedAnswers = formFields.map((obj) => {
      let answer = answers[`field_${obj.id}DataUrl`] || answers[`field_${obj.id}`];
      if (obj.fieldType === 'address') {
        answer = `${answer?.addressLine1 || ''}${answer?.addressLine2 ? ` ${answer?.addressLine2}` : ''}, ${answer?.city || ''}, ${answer?.state || ''} ${answer?.zipCode || ''}`;
      }
      return {
        answer,
        fieldType: obj.fieldType,
        name: obj.placeholder || snakeCaseToTitleCase(obj.fieldType),
        position: obj.position,
      };
    });

    return formattedAnswers;
  };

  return (
    <React.Fragment>
      <AH typography className={classes.advancedFormName} defaultMessage="Untitled Form" variant="h3">
        {advancedForm?.formName || t('advanced_forms.advancedFormDefaultName')}
      </AH>
      <AHLevelProvider>
        <BasicForm
          aria-label={advancedForm?.formName || undefined}
          labelPosition="top"
          mutate={{
            mutation: createAdvancedFormEntry,
            onCompleted: () => {
              setSubmitted(true);
            },
            toVariables: (variables) => {
              const formVariables = {
                advancedFormId: advancedForm.id,
                answers: formatAnswers(variables),
                captchaResponse,
              };

              if (locationId) {
                formVariables.locationId = locationId;
              }

              return formVariables;
            },
          }}
        >
          {({ values, setValue }) => (
            <React.Fragment>
              <AdvancedFormsFieldFactory
                values={values}
                setValue={setValue}
                advancedForm={advancedForm}
                classes={classes}
                restaurantId={restaurant.id}
                setLocationId={setLocationId}
              />
              {renderCaptcha && (
              <Captcha
                onChange={response => setCaptchaResponse(response)}
              />
              )}
              <SubmitGroup
                className={classes.formButton}
                ButtonProps={{ className: 'gtm-advanced-form-submit-button' }}
                justify="left"
                disabled={!captchaResponse}
                title={t('advanced_forms.advancedFormSubmit')}
              />
            </React.Fragment>
          )}
        </BasicForm>
      </AHLevelProvider>
    </React.Fragment>
  );
};

AdvancedFormsSection.defaultProps = {};

AdvancedFormsSection.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  withIntl,
  withTheme,
  withStyles(advancedFormStyles),
)(AdvancedFormsSection);
