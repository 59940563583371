import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Icon, Tooltip } from '@popmenu/common-ui';
import { Help, CheckCircle, Circle } from '@popmenu/web-icons';

import Grid from '../../../shared/Grid';

const CheckBox = (props) => {
  const { checked, className, disabled, field, onChange, radio, radioCheck, rootClassName, title, titleTooltip, value, ...inputProps } = props;
  delete inputProps['data-tour-id']; // Only apply data-tour-id to actual checkbox input
  const name = typeof field === 'string' ? field : field[0];
  let label;
  if (titleTooltip) {
    label = (
      <Grid container inline spacing={0} style={{ whiteSpace: 'normal' }}>
        <Grid item>
          {title}
        </Grid>
        <Grid
          item
          onClick={(e) => {
            // Called to prevent click on tooltip icon from toggling checkbox
            e.preventDefault();
            e.stopPropagation();
            return false;
          }}
        >
          <Tooltip title={titleTooltip}>
            <div>
              <Icon icon={Help} style={{ marginLeft: '8px', position: 'relative', top: '4px' }} />
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    );
  } else {
    label = title;
  }

  let checkedIcon;
  let icon;
  let indeterminateIcon;

  if (radio || radioCheck) {
    checkedIcon = <Icon icon={CheckCircle} />;
    icon = <Icon icon={Circle} />;
    indeterminateIcon = <Icon icon={Circle} />;
  }

  if (radioCheck) {
    checkedIcon = <Icon icon={CheckCircle} />;
  }

  // console.log(`CheckBox.render ${field}`);
  return (
    <FormControlLabel
      className={className}
      control={(
        <Checkbox
          color="primary"
          autoComplete="off"
          checked={checked}
          checkedIcon={checkedIcon}
          icon={icon}
          indeterminateIcon={indeterminateIcon}
          inputProps={{
            'data-tour-id': props['data-tour-id'],
          }}
          name={name}
          onChange={onChange}
          classes={{
            root: rootClassName,
          }}
          value={String(value)}
          {...inputProps}
        />
      )}
      disabled={disabled}
      label={label}
    />
  );
};

CheckBox.defaultProps = {
  checked: false,
  className: null,
  color: 'primary',
  'data-tour-id': null,
  disabled: false,
  onChange: undefined,
  radio: false,
  radioCheck: false,
  rootClassName: null,
  title: null,
  titleTooltip: null,
  value: true,
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  'data-tour-id': PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  radio: PropTypes.bool,
  radioCheck: PropTypes.bool,
  rootClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  titleTooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
};

export default CheckBox;
