import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@popmenu/common-ui';
import { X as XIcon } from '@popmenu/web-icons';

import { classNames, makeStyles } from '../../../utils/withStyles';
import JSS from '../../../assets/jss/shared/formNestedFieldStyles';

const removeButtonClass = (classes, webBuilder, crossSelling) => {
  if (webBuilder) {
    return classes.webBuilderRemoveButton;
  } else if (crossSelling) {
    return classes.crossSellingRemoveButton;
  } else {
    return classes.removeButton;
  }
};

const NestedField = (props) => {
  const { canRemove, children, className, crossSelling, field, handle, onRemove, reorder, showDragOnHover, styles, webBuilder } = props;
  const { FieldScope, setValue, value } = useField(String(field));

  // hover state used for dragHandle visibility
  const [hover, setHover] = useState(false);
  const useStyles = makeStyles(JSS);
  const classes = useStyles({ ...props, hover, showDragOnHover });
  const handleMouseIn = (e) => {
    // check if any mouse btns are down to prevent background color changing when dragging
    if (!e.buttons) {
      setHover(true);
    }
  };
  const handleMouseOut = () => {
    setHover(false);
  };

  const hoverListeners = showDragOnHover ? {
    onBlur: handleMouseOut,
    onFocus: handleMouseIn,
    onMouseOut: handleMouseOut,
    onMouseOver: handleMouseIn,
  } : {};

  if (!children) {
    return null;
  }
  return (
    <FieldScope>
      <div className={classNames(className, classes.wrapper, 'pm-nested-field')} style={styles}>
        <div
          className={classNames(classes.wrapperInner, (handle && reorder) ? classes.wrapperInnerWithDragHandle : null)}
          {...hoverListeners}
        >
          {handle && reorder && (
            <div className={classes.dragHandle}>
              {handle}
            </div>
          )}
          <div className={classNames((handle && reorder) ? classes.fieldWithDragHandle : null)}>
            {typeof children === 'function' ? children({ setValue, value }) : children}
          </div>
          {canRemove && (
            <IconButton
              aria-label="Remove"
              className={removeButtonClass(classes, webBuilder, crossSelling)}
              data-cy="remove_field"
              onClick={onRemove}
              size="small"
            >
              <Icon icon={XIcon} />
            </IconButton>
          )}
        </div>
      </div>
    </FieldScope>
  );
};

NestedField.defaultProps = {
  canRemove: false,
  className: null,
  crossSelling: true,
  handle: null,
  onRemove: null,
  reorder: true,
  showDragOnHover: false,
  styles: {},
};

NestedField.propTypes = {
  canRemove: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  crossSelling: PropTypes.bool,
  field: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handle: PropTypes.node,
  onRemove: PropTypes.func,
  reorder: PropTypes.bool,
  showDragOnHover: PropTypes.bool,
  styles: PropTypes.object,
};

export default NestedField;
